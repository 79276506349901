import http from '@/utils/request'

/**
 * 新闻类型
 */
export function newsType(parameter) {
  return http.get('/sys/dict/getItems/news_type',{params:parameter})
}
/**
 * 新闻列表
 */
export function newsList(parameter) {
  return http.get('/show/news/list',{params:parameter})
}

/**
 * 新闻详情
 */
export function newsDetail(parameter) {
  return http.get('/show/news/detail',{params:parameter})
}
/**
 * 艺人列表
 */
export function actorList(parameter) {
  return http.get('/show/actor/list',{params:parameter})
}


/**
 * 影视列表
 */
export function moviesList(parameter) {
  return http.get('/show/movies/list',{params:parameter})
}

/**
 * 影视详情
 */
export function moviesDetail(parameter) {
  return http.get('/show/movies/detail',{params:parameter})
}
/**
 * 影视状态
 */
export function moviesStatus(parameter) {
  return http.get('/sys/dict/getItems/start_status',{params:parameter})
}
/**
 * 影视分类
 */
export function moviesType(parameter) {
  return http.get('/sys/dict/getItems/movies_type',{params:parameter})
}

/**
 * 合作伙伴
 */
export function partnerList(parameter) {
  return http.get('/show/partner/list',{params:parameter})
}

/**
 * 我的份额
 */
export function shareList(parameter) {
  return http.get('/myself/share/list',{params:parameter})
}

/**
 * 份额申请
 */
export function shareApply(data) {
  return http.post('/myself/share/apply',data)
}

/**
 * 图片轮播
 */
export function rotationList(data) {
  return http.get('/show/picture/rotationList',{params:data})
}